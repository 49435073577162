import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { User } from '@/types/user'

interface CreateUserBody {
  uuid: string
  email: string
  provider: 'password' | 'saml'
  password?: string
}

export function createUser(body: CreateUserBody): Promise<AxiosResponse<User>> {
  return instance.post(`/iam/user`, body)
}
