
import { defineComponent, computed, ref, watch } from 'vue'
import QInput from 'quasar/src/components/input/QInput.js';import QTable from 'quasar/src/components/table/QTable.js';
import MasterTable from '@/components/master/MasterTable.vue'
import { useUser } from '@/composable/useUser'
import { useRole } from '@/composable/useRole'
import { useProject } from '@/composable/useProject'
import { User } from '@/types/user'
import {
  required as requiredRule,
  minCharactersRule,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import MasterRoleTree, {
  MasterRoleTreeOption,
} from '@/components/master/MasterRoleTree.vue'

export default defineComponent({
  name: 'MasterUsers',

  components: {
    MasterTable,
    MasterRoleTree,
  },

  setup() {
    const {
      loading: loadingStateUsers,
      data: users,
      add,
      remove,
      update,
    } = useUser(true)
    const { loading: loadingStateProjects, data: projects } = useProject(true)
    const { loading: loadingStateRoles, data: roles } = useRole(true)

    const filterPermission = ref('')
    const showPassword = ref(true)
    const passwordRef = ref<QInput | null>(null)

    function createFormModel(): User {
      return {
        uuid: uuidv4(),
        email: '',
        projects: [],
        roles: [],
        provider: 'password',
        password: '',
        preventPasswordReset: false,
      }
    }
    const model = ref<User>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const loading = computed(() => {
      return {
        ...loadingStateUsers.value,
        getAll:
          loadingStateUsers.value.getAll ||
          loadingStateProjects.value.getAll ||
          loadingStateRoles.value.getAll,
      }
    })

    const columns: QTable['columns'] = [
      {
        name: 'email',
        label: 'Email',
        align: 'left',
        field: 'email',
        sortable: true,
      },
      {
        name: 'projects',
        label: 'Projekt',
        align: 'left',
        field: 'projects',
        format: (v: string[]) => {
          return v
            .map((uuid: string) => {
              return (
                projects?.value.find((x) => x.uuid === uuid)?.description ||
                null
              )
            })
            .join(', ')
        },
        sortable: true,
      },
      {
        name: 'roles',
        label: 'Roller',
        align: 'left',
        field: 'roles',
        format: (v: string[]) => {
          return v
            .map((uuid: string) => {
              return (
                roles?.value.find((x) => x.uuid === uuid)?.description || null
              )
            })
            .join(', ')
        },
        sortable: true,
      },
      {
        name: 'provider',
        label: 'Provider',
        align: 'left',
        field: 'provider',

        sortable: true,
      },
      {
        name: 'lastSignedIn',
        label: 'Senast inloggad',
        align: 'left',
        field: 'lastSignedIn',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        name: 'created',
        label: 'Skapad',
        align: 'left',
        field: 'created',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        name: 'updated',
        label: 'Uppdaterad',
        align: 'left',
        field: 'updated',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
    ]

    const exportData = computed(() => {
      interface ExportRow {
        [key: string]: unknown
      }

      return users.value.map((user) => {
        return columns.reduce<ExportRow>((acc, column) => {
          const value = user[column.field as keyof User]
          acc[column.label] =
            column.format && value ? column.format(value, null) : value
          return acc
        }, {})
      })
    })

    function updateFormModel(row: User) {
      model.value = {
        ...model.value,
        ...row,
      }
      model.value.password = ''
    }

    watch(
      () => model.value.password,
      (value) => {
        if (passwordRef.value && !value) {
          passwordRef.value.resetValidation()
        }
      }
    )

    const rolesGrouped = computed(() => {
      return projects.value.reduce<MasterRoleTreeOption[]>((acc, project) => {
        const rolesInProject = roles.value.filter(
          (role) => role.projectUuid === project.uuid
        )

        rolesInProject.forEach((role) => {
          acc.push({
            ...role,
            group: project.description,
          })
        })

        return acc
      }, [])
    })

    watch(
      () => model.value.roles,
      (roleUuids) => {
        if (!projects.value || !roles.value) return
        const filterUniqueProjects = roleUuids.reduce<{
          [uuid: string]: string
        }>((acc, roleUuid) => {
          const role = roles.value.find((role) => role.uuid === roleUuid)
          if (!role) return acc
          const foundProject = projects.value.find(
            (project) => project.uuid === role.projectUuid
          )

          if (foundProject) {
            acc[foundProject.uuid] = foundProject.uuid
          }

          return acc
        }, {})

        model.value.projects = Object.values(filterUniqueProjects)
      }
    )

    return {
      minCharactersRule: minCharactersRule(8),
      showPassword,
      filterPermission,
      columns,
      roles,
      model,
      requiredRule,
      users,
      resetFormModel,
      exportData,
      loading,
      projects,
      loadingStateRoles,
      loadingStateProjects,
      updateFormModel,
      add,
      update,
      remove,
      passwordRef,
      rolesGrouped,
    }
  },
})
