import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { User } from '@/types/user'

interface UpdateUserBody {
  email: string
  password?: string
}

export function updateUser(
  uuid: string,
  body: UpdateUserBody
): Promise<AxiosResponse<User>> {
  return instance.patch(`/iam/user/${uuid}`, body)
}
