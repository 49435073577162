<template>
  <MasterTable
    title="Användare"
    :rows="users"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    remove-item-text-prop="email"
    @update-form-model="updateFormModel"
  >
    <template v-slot:form="{ formMode }">
      <div class="row" style="min-width: 700px; height: 400px">
        <div class="col-5 q-pa-md">
          <div class="row">
            <div class="col-12 q-gutter-sm q-pb-md">
              <q-radio
                :disable="formMode === 'update'"
                v-model="model.provider"
                val="password"
                label="Email/lösenord"
              />
              <q-radio
                :disable="true"
                v-model="model.provider"
                val="saml"
                label="SSO"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                autocomplete="off"
                dense
                v-model="model.email"
                type="email"
                label="Email*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <q-slide-transition>
            <div class="row" v-if="model.provider === 'password'">
              <div
                class="col-12"
                :class="[
                  !model.password.length && formMode === 'update' && 'q-pb-md',
                ]"
              >
                <q-input
                  autocomplete="off"
                  dense
                  ref="passwordRef"
                  v-model="model.password"
                  :label="formMode === 'update' ? 'Byt lösenord' : 'Lösenord*'"
                  :type="!showPassword ? 'password' : 'text'"
                  :rules="
                    (model.password.length && formMode === 'update') ||
                    formMode === 'create'
                      ? [requiredRule, minCharactersRule]
                      : []
                  "
                >
                  <template v-slot:append>
                    <q-icon
                      :name="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      class="cursor-pointer"
                      @click="showPassword = !showPassword"
                    />
                  </template>
                </q-input>
                <div class="col-12 q-pt-md">
                  <q-checkbox
                    label="Förhindra användare att byta lösenord"
                    v-model="model.preventPasswordReset"
                  />
                </div>
              </div>
            </div>
          </q-slide-transition>
        </div>
        <div class="col-7" style="border-left: 1px solid rgba(0, 0, 0, 0.12)">
          <MasterRoleTree
            :height="400"
            v-model="model.roles"
            :options="rolesGrouped"
            filter-text="Filtrera projekt..."
            validation-text="Minst ett projekt med roll måste väljas"
          />
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { QInput, QTable } from 'quasar'
import MasterTable from '@/components/master/MasterTable.vue'
import { useUser } from '@/composable/useUser'
import { useRole } from '@/composable/useRole'
import { useProject } from '@/composable/useProject'
import { User } from '@/types/user'
import {
  required as requiredRule,
  minCharactersRule,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import MasterRoleTree, {
  MasterRoleTreeOption,
} from '@/components/master/MasterRoleTree.vue'

export default defineComponent({
  name: 'MasterUsers',

  components: {
    MasterTable,
    MasterRoleTree,
  },

  setup() {
    const {
      loading: loadingStateUsers,
      data: users,
      add,
      remove,
      update,
    } = useUser(true)
    const { loading: loadingStateProjects, data: projects } = useProject(true)
    const { loading: loadingStateRoles, data: roles } = useRole(true)

    const filterPermission = ref('')
    const showPassword = ref(true)
    const passwordRef = ref<QInput | null>(null)

    function createFormModel(): User {
      return {
        uuid: uuidv4(),
        email: '',
        projects: [],
        roles: [],
        provider: 'password',
        password: '',
        preventPasswordReset: false,
      }
    }
    const model = ref<User>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const loading = computed(() => {
      return {
        ...loadingStateUsers.value,
        getAll:
          loadingStateUsers.value.getAll ||
          loadingStateProjects.value.getAll ||
          loadingStateRoles.value.getAll,
      }
    })

    const columns: QTable['columns'] = [
      {
        name: 'email',
        label: 'Email',
        align: 'left',
        field: 'email',
        sortable: true,
      },
      {
        name: 'projects',
        label: 'Projekt',
        align: 'left',
        field: 'projects',
        format: (v: string[]) => {
          return v
            .map((uuid: string) => {
              return (
                projects?.value.find((x) => x.uuid === uuid)?.description ||
                null
              )
            })
            .join(', ')
        },
        sortable: true,
      },
      {
        name: 'roles',
        label: 'Roller',
        align: 'left',
        field: 'roles',
        format: (v: string[]) => {
          return v
            .map((uuid: string) => {
              return (
                roles?.value.find((x) => x.uuid === uuid)?.description || null
              )
            })
            .join(', ')
        },
        sortable: true,
      },
      {
        name: 'provider',
        label: 'Provider',
        align: 'left',
        field: 'provider',

        sortable: true,
      },
      {
        name: 'lastSignedIn',
        label: 'Senast inloggad',
        align: 'left',
        field: 'lastSignedIn',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        name: 'created',
        label: 'Skapad',
        align: 'left',
        field: 'created',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        name: 'updated',
        label: 'Uppdaterad',
        align: 'left',
        field: 'updated',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
    ]

    const exportData = computed(() => {
      interface ExportRow {
        [key: string]: unknown
      }

      return users.value.map((user) => {
        return columns.reduce<ExportRow>((acc, column) => {
          const value = user[column.field as keyof User]
          acc[column.label] =
            column.format && value ? column.format(value, null) : value
          return acc
        }, {})
      })
    })

    function updateFormModel(row: User) {
      model.value = {
        ...model.value,
        ...row,
      }
      model.value.password = ''
    }

    watch(
      () => model.value.password,
      (value) => {
        if (passwordRef.value && !value) {
          passwordRef.value.resetValidation()
        }
      }
    )

    const rolesGrouped = computed(() => {
      return projects.value.reduce<MasterRoleTreeOption[]>((acc, project) => {
        const rolesInProject = roles.value.filter(
          (role) => role.projectUuid === project.uuid
        )

        rolesInProject.forEach((role) => {
          acc.push({
            ...role,
            group: project.description,
          })
        })

        return acc
      }, [])
    })

    watch(
      () => model.value.roles,
      (roleUuids) => {
        if (!projects.value || !roles.value) return
        const filterUniqueProjects = roleUuids.reduce<{
          [uuid: string]: string
        }>((acc, roleUuid) => {
          const role = roles.value.find((role) => role.uuid === roleUuid)
          if (!role) return acc
          const foundProject = projects.value.find(
            (project) => project.uuid === role.projectUuid
          )

          if (foundProject) {
            acc[foundProject.uuid] = foundProject.uuid
          }

          return acc
        }, {})

        model.value.projects = Object.values(filterUniqueProjects)
      }
    )

    return {
      minCharactersRule: minCharactersRule(8),
      showPassword,
      filterPermission,
      columns,
      roles,
      model,
      requiredRule,
      users,
      resetFormModel,
      exportData,
      loading,
      projects,
      loadingStateRoles,
      loadingStateProjects,
      updateFormModel,
      add,
      update,
      remove,
      passwordRef,
      rolesGrouped,
    }
  },
})
</script>
