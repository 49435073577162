import { render } from "./Users.vue?vue&type=template&id=c32d1cda"
import script from "./Users.vue?vue&type=script&lang=ts"
export * from "./Users.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QRadio,QInput,QSlideTransition,QIcon,QCheckbox});
