import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { User } from '@/types/user'

interface CreateUserRolesBody {
  userUuid: string
  roles: string[]
}

export function createUserRoles(
  body: CreateUserRolesBody
): Promise<AxiosResponse<User>> {
  return instance.post(`/iam/setUserRoles`, body)
}
