import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { User } from '@/types/user'

interface CreateUserProjectsBody {
  userUuid: string
  projects: string[]
}

export function createUserProjects(
  body: CreateUserProjectsBody
): Promise<AxiosResponse<User>> {
  return instance.post(`/iam/setUserProjects`, body)
}
