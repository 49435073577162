import { Ref, ref, ComputedRef, computed } from 'vue'
import { User } from '@/types/user'
import { getUsers } from '@/api/user/getUsers'
import { createUser } from '@/api/user/createUser'
import { updateUser } from '@/api/user/updateUser'
import { createUserProjects } from '@/api/user/createUserProjects'
import { createUserRoles } from '@/api/user/createUserRoles'
import { deleteUser } from '@/api/user/deleteUser'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseUser {
  data: Ref<User[]>
  fetchAll: () => Promise<User[]>
  add: (body: User) => Promise<User>
  remove: (uuid: string) => Promise<null>
  update: (body: User) => Promise<User>
  loading: ComputedRef<Loading>
}

export function useUser(initFetchAll?: boolean): UseUser {
  const data = ref<User[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<User[]>((resolve, reject) => {
      getUsers()
        .then(({ data: users }) => {
          data.value = users
          resolve(users)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function add(body: User) {
    state.create = true
    return new Promise<User>((resolve, reject) => {
      const createUserBody = {
        uuid: body.uuid,
        email: body.email,
        provider: body.provider,
        password: body.password,
        preventPasswordReset: body.preventPasswordReset,
      }

      if (body.provider !== 'password') {
        delete createUserBody.password
      }

      createUser(createUserBody)
        .then(({ data: user }) => ({ ...user, ...body }))
        .then(async (user) => {
          const param = {
            userUuid: body.uuid,
            projects: body.projects,
          }
          return createUserProjects(param).then(() => user)
        })
        .then(async (user) => {
          const param = {
            userUuid: body.uuid,
            roles: body.roles,
          }
          return createUserRoles(param).then(() => user)
        })
        .then((user) => {
          data.value.push(user)
          resolve(user)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: User) {
    state.update = true
    return new Promise<User>((resolve, reject) => {
      const updateUserBody = {
        email: body.email,
        password: body.password,
        preventPasswordReset: body.preventPasswordReset,
      }

      if (!body.password || !body.password.length) {
        delete updateUserBody.password
      }

      updateUser(body.uuid, updateUserBody)
        .then(({ data: user }) => ({ ...user, ...body }))
        .then(async (user) => {
          const param = {
            userUuid: body.uuid,
            projects: body.projects,
          }
          return createUserProjects(param).then(() => user)
        })
        .then(async (user) => {
          const param = {
            userUuid: body.uuid,
            roles: body.roles,
          }
          return createUserRoles(param).then(() => user)
        })
        .then((user) => {
          data.value = data.value.map((item) => {
            if (item.uuid === user.uuid) {
              return {
                ...user,
                ...body,
              }
            }

            return item
          })
          resolve(user)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteUser(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
  }
}
